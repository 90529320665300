import React from 'react';
import Wrapper from "../components/common/Wrapper"

const About = () => {
   return (
      <>
         <Wrapper className="main-layout">
            <div id="about" className="about">
               <div className="container">
                  <div className="row d_flex">
                     <div className="col-md-7">
                        <div className="titlepage">
                           <h2>About Us</h2>
                           <span></span>
                           <p>
                              We have the ability to provide you with a best web presence. We have our own competitive goal set up to develop web alternatives that happen to be beyond the expectation and imagination of the client.
                              Our team members assist you in a professional manner, analyse your web needs, and work accordingly. We provide all the services from custom software development to designing, along with web promotion. We follow proper strategic plans for our result-oriented work. We enable you to illustrate and target your clients so that you can simply offer them the appropriate services they are seeking.
                           </p>
                           <a className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                        </div>
                     </div>
                     <div className="col-md-5">
                        <div className="about_img">
                           <figure><img src="images/about_img.png" alt="#" /></figure>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Wrapper>
      </>
   )
}

export default About