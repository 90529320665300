import React from 'react'
import Wrapper from "../components/common/Wrapper"
import AssistanceForm from '../components/common/AssistanceForm'

const Assistance = () => {
  return (
    <Wrapper className="main-layout">
      <AssistanceForm />
    </Wrapper>
  )
}

export default Assistance