import React from 'react';
import RequestContact from "../components/common/RequestContact"
import Wrapper from "../components/common/Wrapper"

const Contact = () => {
  return (
    <>
      <Wrapper className="main-layout">
        <RequestContact />
      </Wrapper>
    </>
  )
}

export default Contact