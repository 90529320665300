export const OUR_WORK=[
    "images/our.jpg",
    "images/our1.jpg",
    "images/our.jpg",
    "images/our1.jpg",
    "images/our.jpg",
    "images/our1.jpg",
]

export const TECHNOLOGIES=[
    {
        lable:"React",
        value:"react"
    },
    {
        lable:"NodeJs",
        value:"nodejs"
    },
    {
        lable:"Angular",
        value:"angular"
    },
    {
        lable:"NextJs",
        value:"nextjs"
    },
    {
        lable:"PHP",
        value:"php"
    },
    {
        lable:"HTML",
        value:"html"
    },
    {
        lable:"CSS",
        value:"css"
    },
    {
        lable:"Javascript",
        value:"javascript"
    },
    {
        lable:"GraphQL",
        value:"graphql"
    },
    {
        lable:"MongoDB",
        value:"mongodb"
    },
]