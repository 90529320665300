import React from 'react'
import { Link } from 'react-router-dom'

const AssistanceButton = () => {
  const scroll=()=>{
    document.body.scrollIntoView({block:'start'});
  }
  return (
    <div className='position-fixed assistance-float-btn'>
      <Link to={'/assistance'}>      
        <button className=' bg-transparent ' title='Book Assistance' onClick={scroll}>
            <img src='images/assistance.png' alt='assistance' height={60} width={60}/>
        </button>
      </Link>
    </div>
  )
}

export default AssistanceButton