import React, { useEffect, useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../services/firebase';


const RequestContact = () => {

  const [form ,setForm]=useState({
   name:"",
   phone:"",
   email:"",
   message:"",
   location:{}
  }) 

  useEffect(()=>{
   // if (navigator.geolocation) {
   //    navigator.geolocation.getCurrentPosition(function(position) {
   //      const latitude = position.coords.latitude;
   //      const longitude = position.coords.longitude;
   //      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
   //    });
   //  } else {
   //    console.log("Geolocation is not supported by this browser.");
   //  }
  },[])

  const handleChange=(event)=>{
   const {name, value}= event.target;
   setForm({
      ...form,
      [name]:value
   })
  }

  const submit=async (event)=>{
   event.preventDefault();
   try {
      await addDoc(collection(db, "contacts"), {
         contact: form,    
      });
      setForm({
         name:"",
         phone:"",
         email:"",
         message:""
      })
   } catch (error) {
      console.log(error)
   }
  }
  return (
    <>
     <div id="contact" className="contact">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="titlepage">
                     <h2>Request A call back</h2>
                  </div>
               </div>
               <div className="col-md-6 offset-md-3">
                  <form id="request" onSubmit={submit} method='POST' className="main_form">
                     <div className="row">
                        <div className="col-md-12 ">
                           <input className="contactus" value={form.name} onChange={handleChange} placeholder="Full Name" maxLength={32} type="text" name="name" /> 
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" value={form.phone} onChange={handleChange} placeholder="Phone Number" maxLength={20} type="tel" name="phone" /> 
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" value={form.email} onChange={handleChange} placeholder="Email" type="email" name="email" />                          
                        </div>
                        <div className="col-md-12"> 
                           <textarea className="contactus" value={form.message} onChange={handleChange} placeholder="Message" name='message' maxLength={300}> </textarea>
                        </div>
                        <div className="col-sm-12">
                           <button type='submit' className="send_btn">Send</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
    </>
  )
}

export default RequestContact