import React from 'react'
import Header from './Header'
import Footer from './Footer'
import AssistanceButton from './AssistanceButton'

const Wrapper = (props) => {
  return (
    <div className={props?.className}>
      <Header />
          {props?.children}
      <Footer />
      <AssistanceButton />
    </div>
  )
}

export default Wrapper