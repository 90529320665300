import React from 'react'

const CopyRight = () => {
  return (
    <>
        <div className="copyright">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <p>© 2023 All Rights Reserved. <a href="https://abc.com/"> Developers</a></p>
                     </div>
                  </div>
               </div>
        </div>
    </>
  )
}

export default CopyRight