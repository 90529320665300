import React from 'react'
import Carousel from "../components/Carousel"
import RequestContact from "../components/common/RequestContact"
import Wrapper from "../components/common/Wrapper"
import { Link } from 'react-router-dom'

const Home = () => {
   return (
      <>
         <Wrapper className="main-layout">
            <Carousel />
            <div id="about" className="about">
               <div className="container">
                  <div className="row d_flex">
                     <div className="col-md-7">
                        <div className="titlepage">
                           <h2>Web Development</h2>
                           <span></span>
                           <p>
                              Mastering web development requires understanding both frontend and backend technologies. From HTML and CSS to advanced JavaScript frameworks and server-side languages, each component plays a crucial role in creating functional websites.
                           </p>
                           <Link to={'/web-development'} className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                     </div>
                     <div className="col-md-5">
                        <div className="about_img">
                           <figure><img src="images/about_img.png" alt="#" /></figure>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="mobile" className="mobile">
               <div className="container">
                  <div className="row d_flex">
                     <div className="col-md-5">
                        <div className="mobile_img">
                           <figure><img src="images/mobile.png" alt="#" /></figure>
                        </div>
                     </div>
                     <div className="col-md-7">
                        <div className="titlepage">
                           <h2>Mobile App Development</h2>
                           <span></span>
                           <p>
                              Mobile app development involves creating applications for iOS and Android platforms. It encompasses designing intuitive interfaces and ensuring seamless functionality using languages like Swift, Kotlin, and Java.
                           </p>
                           <Link to={'/mobile-development'} className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="backend" className="about">
               <div className="container">
                  <div className="row d_flex">
                     <div className="col-md-7">
                        <div className="titlepage">
                           <h2>Backend Development</h2>
                           <span></span>
                           <p>
                              Backend development involves server-side logic, databases, and application integration. Using languages like Python, Node.js, or Ruby, developers create robust systems to handle data processing, user authentication, and server communication.
                           </p>
                           <Link to={'/backend-development'} className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                     </div>
                     <div className="col-md-5">
                        <div className="about_img">
                           <figure><img src="images/about_img.png" alt="#" /></figure>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="full-stack" className="mobile">
               <div className="container">
                  <div className="row d_flex">
                     <div className="col-md-5">
                        <div className="mobile_img">
                           <figure><img src="images/mobile.png" alt="#" /></figure>
                        </div>
                     </div>
                     <div className="col-md-7">
                        <div className="titlepage">
                           <h2>Full Stack Development</h2>
                           <span></span>
                           <p>
                              Full stack development merges frontend and backend skills, enabling developers to build complete web applications. This includes designing user interfaces, managing databases, and implementing server-side logic for seamless functionality.
                           </p>
                           <Link to={'/full-stack-development'} className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="clients">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6 offset-md-3">
                        <div className="titlepage">
                           <h2>What is Say clients</h2>
                           <span></span>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="clients_box">
                           <p>
                              Excellent work and communication. Their readiness to please is exceptional. Will definitely use them again. Thank you Cozeniths team.
                           </p>
                        </div>
                        <div className="jonu">
                           <img src="images/cross_img.png" alt="#" />
                           <h3>Jone due</h3>
                           <strong>(sure there isn't)</strong>
                           <a className="read_more" href="#">Get A Quote</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <RequestContact />
         </Wrapper>
      </>
   )
}

export default Home