import React from 'react'
import Wrapper from '../../components/common/Wrapper';
import './style.css';

const WebDevelopment = () => {
    return (
        <>
            <Wrapper className="main-layout">
                <div id="about" className="about">
                    <div className="container">
                        <div className="row d_flex">
                            <div className="col-md-7">
                                <div className="titlepage">
                                    <h2>Web Development</h2>
                                    <span></span>
                                    <p>
                                        Mastering web development requires understanding both frontend and backend technologies. From HTML and CSS to advanced JavaScript frameworks and server-side languages, each component plays a crucial role in creating functional websites.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="about_img">
                                    <figure><img src="images/about_img.png" alt="#" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <header>
                        <h1>Web Development Process Steps</h1>
                    </header>

                    <section class="step">
                        <h2>1. Planning and Analysis</h2>
                        <h3>Goal Setting</h3>
                        <p>Define the purpose of the website, target audience, and primary goals.</p>
                        <h3>Requirement Gathering</h3>
                        <p>Collect detailed requirements from stakeholders, including features and functionalities needed.</p>
                        <h3>Research and Analysis</h3>
                        <p>Conduct competitor analysis, user research, and market trends study to inform design and functionality decisions.</p>
                    </section>

                    <section class="step">
                        <h2>2. Project Scope Definition</h2>
                        <h3>Project Scope Document</h3>
                        <p>Create a document outlining the project scope, deliverables, timelines, and resources required.</p>
                        <h3>Sitemap Creation</h3>
                        <p>Develop a sitemap that outlines the structure and hierarchy of the website.</p>
                    </section>

                    <section class="step">
                        <h2>3. Design</h2>
                        <h3>Wireframes and Mockups</h3>
                        <p>Create wireframes to outline the layout and structure of web pages. Develop high-fidelity mockups to showcase the visual design.</p>
                        <h3>Prototyping</h3>
                        <p>Build interactive prototypes to demonstrate user flow and functionality.</p>
                        <h3>Design Approval</h3>
                        <p>Review and approve the designs with stakeholders.</p>
                    </section>

                    <section class="step">
                        <h2>4. Content Creation</h2>
                        <h3>Content Strategy</h3>
                        <p>Develop a content strategy that aligns with the website’s goals and target audience.</p>
                        <h3>Content Development</h3>
                        <p>Write, edit, and gather all necessary content including text, images, videos, and other multimedia.</p>
                    </section>

                    <section class="step">
                        <h2>5. Development</h2>
                        <h3>Front-End Development</h3>
                        <p>Code the website’s front-end using HTML, CSS, and JavaScript to create the user interface and interactivity.</p>
                        <h3>Back-End Development</h3>
                        <p>Develop the server-side logic, databases, and application functionality using back-end technologies (e.g., Node.js, Python, Ruby, PHP).</p>
                        <h3>Content Management System (CMS) Integration</h3>
                        <p>Integrate a CMS if required, allowing non-technical users to manage website content.</p>
                    </section>

                    <section class="step">
                        <h2>6. Testing</h2>
                        <h3>Functionality Testing</h3>
                        <p>Test all features and functionalities to ensure they work as intended.</p>
                        <h3>Usability Testing</h3>
                        <p>Conduct usability testing to ensure the website is user-friendly.</p>
                        <h3>Performance Testing</h3>
                        <p>Test the website’s load times, responsiveness, and overall performance.</p>
                        <h3>Compatibility Testing</h3>
                        <p>Ensure the website is compatible with different browsers and devices.</p>
                        <h3>Security Testing</h3>
                        <p>Identify and fix security vulnerabilities.</p>
                    </section>

                    <section class="step">
                        <h2>7. Deployment</h2>
                        <h3>Server Setup</h3>
                        <p>Prepare the server environment for deployment, including setting up hosting and domain configuration.</p>
                        <h3>Code Deployment</h3>
                        <p>Deploy the website’s code to the production server.</p>
                        <h3>Final Review</h3>
                        <p>Conduct a final review and quality assurance check to ensure everything is in place and working correctly.</p>
                    </section>

                    <section class="step">
                        <h2>8. Maintenance and Updates</h2>
                        <h3>Regular Updates</h3>
                        <p>Update the website regularly with new content, features, and security patches.</p>
                        <h3>Performance Monitoring</h3>
                        <p>Monitor website performance and make improvements as needed.</p>
                        <h3>User Feedback</h3>
                        <p>Collect user feedback and make iterative improvements to the website.</p>
                        <h3>Backup and Recovery</h3>
                        <p>Implement a backup and recovery plan to prevent data loss.</p>
                    </section>

                    <section class="step">
                        <h2>9. Marketing and Optimization</h2>
                        <h3>SEO Optimization</h3>
                        <p>Optimize the website for search engines to improve visibility.</p>
                        <h3>Analytics Setup</h3>
                        <p>Implement analytics tools to track user behavior and website performance.</p>
                        <h3>Digital Marketing</h3>
                        <p>Use digital marketing strategies like social media, email marketing, and online advertising to drive traffic to the website.</p>
                    </section>

                </div>
            </Wrapper>
        </>
    )
}

export default WebDevelopment