import { createBrowserRouter } from "react-router-dom";
import About from "./pages/About";
import Client from "./pages/Client";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import OurWork from "./pages/OurWork";
import WebDevelopment from "./pages/services/WebDevelopment";
import BackendDevelopment from "./pages/services/BackendDevelopment";
import MobileDevelopment from "./pages/services/MobileDevelopment";
import FullStackDevelopment from "./pages/services/FullStackDevelopment";
import Assistance from "./pages/Assistance";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/our-work",
      element: <OurWork />
    },
    {
      path: "/our-client",
      element: <Client/>
    },
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/contact",
      element: <Contact />
    },
    {
      path: "/web-development",
      element: <WebDevelopment />
    },
    {
      path: "/backend-development",
      element: <BackendDevelopment />
    },
    {
      path: "/mobile-development",
      element: <MobileDevelopment />
    },
    {
      path: "/full-stack-development",
      element: <FullStackDevelopment />
    },
    {
      path: "/assistance",
      element: <Assistance />
    },
  ]);

  export default router