import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../services/firebase';
import { TECHNOLOGIES } from '../../constants/constants';


const AssistanceForm = () => {

  const [form ,setForm]=useState({
   name:"",
   phone:"",
   email:"",
   technology:"",
   note:"",
   location:{}
  }) 

  const handleChange=(event)=>{
   const {name, value}= event.target;
   setForm({
      ...form,
      [name]:value
   })
  }

  const submit=async (event)=>{
   event.preventDefault();
   try {
      await addDoc(collection(db, "assistance"), {
         contact: form,    
      });
      setForm({
         name:"",
         phone:"",
         email:"",
         technology:"",
         note:""
      })
   } catch (error) {
      console.log(error)
   }
  }
  return (
    <>
     <div id="contact" className="contact mt-0">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="titlepage">
                     <h2>Book Assistance</h2>
                  </div>
               </div>
               <div className="col-md-6 offset-md-3">
                  <form id="request" onSubmit={submit} method='POST' className="main_form">
                     <div className="row">
                        <div className="col-md-12 ">
                           <input className="contactus"  value={form.name} onChange={handleChange} placeholder="Full Name" maxLength={64} type="text" name="name" /> 
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" value={form.phone} onChange={handleChange} placeholder="Phone Number" maxLength={20} type="tel" name="phone" /> 
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" value={form.email} onChange={handleChange} placeholder="Email" type="email" maxLength={128} name="email" />                          
                        </div>
                        <div className="col-md-12">
                           <select className="contactus" value={form.technology} onChange={handleChange} placeholder="Technology"  name="technology" >
                             <option value='' className='text-dark'>Select Technology</option>
                             {
                              TECHNOLOGIES.map((t)=>
                                 <option value={t.value} className='text-dark pb-2'>{t.lable}</option>
                              )
                             }   
                           </select>                          
                        </div>
                        <div className="col-md-12"> 
                           <textarea className="contactus" value={form.note} onChange={handleChange} placeholder="Write your problem here ..." name='note' maxLength={600}> </textarea>
                        </div>
                        <div className="col-sm-12">
                           <button type='submit' className="send_btn">Submit</button>
                        </div>
                     </div>
                  </form>
                  <div className='mt-3 text-center'> 
                     <p>Note: Assistance take charges based on the time they spends on your task & understanding.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </>
  )
}

export default AssistanceForm