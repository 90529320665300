import React from 'react'
import Wrapper from '../../components/common/Wrapper';
import './style.css';

const MobileDevelopment = () => {
    return (
        <>
            <Wrapper className="main-layout">
                <div id="mobile" className="mobile">
                    <div className="container">
                        <div className="row d_flex">
                            <div className="col-md-5">
                                <div className="mobile_img">
                                    <figure><img src="images/mobile.png" alt="#" /></figure>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="titlepage">
                                    <h2>Mobile App Development</h2>
                                    <span></span>
                                    <p>
                                        Mobile app development involves creating applications for iOS and Android platforms. It encompasses designing intuitive interfaces and ensuring seamless functionality using languages like Swift, Kotlin, and Java.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mt-5'>
                    <header className='my-3'>
                        <h1>Mobile Development Process Steps</h1>
                    </header>

                    <section class="step">
                        <h2>1. Planning and Analysis</h2>
                        <h3>Requirement Gathering</h3>
                        <p>Collect detailed requirements from stakeholders, including desired features, target platforms, and user expectations.</p>
                        <h3>Market Research</h3>
                        <p>Conduct market research to understand the competitive landscape and user needs.</p>
                        <h3>Feasibility Study</h3>
                        <p>Assess the technical feasibility and resource requirements of the project.</p>
                    </section>

                    <section class="step">
                        <h2>2. Project Scope Definition</h2>
                        <h3>Project Scope Document</h3>
                        <p>Create a document outlining the project scope, deliverables, timelines, and resources required.</p>
                        <h3>App Flow and Wireframes</h3>
                        <p>Develop app flow diagrams and wireframes to outline the structure and user experience of the mobile app.</p>
                    </section>

                    <section class="step">
                        <h2>3. Design</h2>
                        <h3>UI/UX Design</h3>
                        <p>Design the user interface and user experience, creating mockups and prototypes to visualize the app’s look and feel.</p>
                        <h3>User Feedback</h3>
                        <p>Gather feedback on the designs from stakeholders and potential users to refine the UI/UX.</p>
                        <h3>Design Approval</h3>
                        <p>Review and approve the final designs with stakeholders.</p>
                    </section>

                    <section class="step">
                        <h2>4. Development</h2>
                        <h3>Environment Setup</h3>
                        <p>Set up the development environment, including necessary tools, SDKs, and code repositories.</p>
                        <h3>Front-End Development</h3>
                        <p>Develop the app's front-end, ensuring it aligns with the design specifications and is responsive across different devices.</p>
                        <h3>Back-End Development</h3>
                        <p>Develop the back-end services, APIs, and database needed to support the mobile app.</p>
                        <h3>Integration</h3>
                        <p>Integrate front-end and back-end components, and third-party services as needed.</p>
                    </section>

                    <section class="step">
                        <h2>5. Testing</h2>
                        <h3>Unit Testing</h3>
                        <p>Write and run unit tests to verify individual components of the mobile app code.</p>
                        <h3>Integration Testing</h3>
                        <p>Conduct integration tests to ensure different parts of the app work together correctly.</p>
                        <h3>Usability Testing</h3>
                        <p>Test the app's usability with real users to gather feedback and identify issues.</p>
                        <h3>Performance Testing</h3>
                        <p>Test the app’s performance, including load times, responsiveness, and resource usage.</p>
                        <h3>Security Testing</h3>
                        <p>Identify and fix security vulnerabilities through penetration testing and code reviews.</p>
                    </section>

                    <section class="step">
                        <h2>6. Deployment</h2>
                        <h3>App Store Preparation</h3>
                        <p>Prepare the app for submission to app stores (e.g., Google Play, Apple App Store), including creating necessary assets and descriptions.</p>
                        <h3>App Store Submission</h3>
                        <p>Submit the app to the relevant app stores and go through the review process.</p>
                        <h3>Deployment to Production</h3>
                        <p>Deploy the app to production, ensuring all back-end services are live and the app is available for download.</p>
                    </section>

                    <section class="step">
                        <h2>7. Post-Launch Monitoring and Maintenance</h2>
                        <h3>Monitoring Setup</h3>
                        <p>Implement monitoring tools to track app performance, user engagement, and error logs.</p>
                        <h3>Regular Updates</h3>
                        <p>Update the app regularly with new features, performance improvements, and security patches.</p>
                        <h3>User Feedback</h3>
                        <p>Collect and analyze user feedback to make iterative improvements to the app.</p>
                        <h3>Incident Management</h3>
                        <p>Establish procedures for incident detection, response, and resolution to minimize downtime.</p>
                    </section>

                    <section class="step">
                        <h2>8. Documentation and Training</h2>
                        <h3>Code Documentation</h3>
                        <p>Document the mobile app codebase, including API endpoints, data models, and key functionalities.</p>
                        <h3>User Guides and Manuals</h3>
                        <p>Create user guides and manuals for end-users and administrators.</p>
                        <h3>Training Sessions</h3>
                        <p>Conduct training sessions for the development team and stakeholders to ensure proper understanding and usage of the mobile app.</p>
                    </section>
                </div>
            </Wrapper>
        </>
    )
}

export default MobileDevelopment