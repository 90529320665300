import React, { useState } from 'react'
import CopyRight from "./CopyRight";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../services/firebase';

const Footer = () => {
  const [email,setEmail]= useState('');
  
  const submit=async(event)=>{
      event.preventDefault();
      try {
          await addDoc(collection(db, "subscribes"), {
            email
         });
         setEmail('');
      } catch (error) {
         console.log(error);
      }
  }
  return (
    <>
    <footer>
         <div className="footer">
            <div className="container">
               <div className="row border-top">
                  <div className="col-md-6 padding_bottom1   ">
                     <h3>Subscribe Now</h3>
                     <form onSubmit={submit} className="footer_form">
                        <input className="enter" placeholder="Enter your email" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} name="email" />
                        <button className="submit">submit</button>
                     </form>
                  </div>
                  <div className="col-md-6">
                     <div className="row">
                        <div className="col-md-5 offset-md-1 padding_bottom1">
                           <h3>Links</h3>
                           <ul className="cont">
                              <li>https://www.cozeniths.com</li>
                              <li>cozeniths@gmail.com</li>
                              <li>https://facebook.com/cozeniths</li>
                           </ul>
                        </div>
                        <div className="col-md-5 offset-md-1">
                           <h3>Contact us</h3>
                           <ul className="cont">
                              <li>Prayagraj Uttar Pradesh, India 2021013 </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <CopyRight />
         </div>
      </footer>
    </>
  )
}

export default Footer