import React from 'react';
import Wrapper from "../components/common/Wrapper"

const Client = () => {
   return (
      <>
         <Wrapper className="main-layout margin_to90">
            <div className="clients">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6 offset-md-3">
                        <div className="titlepage">
                           <h2>What is Say clients</h2>
                           <span></span>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="clients_box">
                           <p>
                              Excellent work and communication. Their readiness to please is exceptional. Will definitely use them again. Thank you Cozeniths team.
                           </p>
                        </div>
                        <div className="jonu">
                           <img src="images/cross_img.png" alt="#" />
                           <h3>Jone due</h3>
                           <strong>(sure there isn't)</strong>
                           <a className="read_more" href="#">Get A Quote</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Wrapper>
      </>
   )
}

export default Client